import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import HeroesLinksList from "@/game-deadlock/components/HeroesLinksList.jsx";
import HeroPageHeader from "@/game-deadlock/components/HeroPageHeader.jsx";
import { TableContainer } from "@/game-deadlock/HeroMatchups.style.jsx";
import { Content } from "@/game-deadlock/HeroOverview.style.jsx";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import { getHeroIdFromName } from "@/game-deadlock/utils/getHeroIdFromName.mjs";
import { localeString } from "@/game-deadlock/utils/localestring.mjs";
import { useHeroStats } from "@/game-deadlock/utils/use-hero-stats.mjs";
import { useHeroesList } from "@/game-deadlock/utils/use-heroes-list.mjs";
import DataTable, { DataTableLoading } from "@/shared/DataTable.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

function Herosynergies() {
  const { t } = useTranslation();
  const {
    parameters: [heroIdOrName],
  } = useRoute();

  const { loading: _heroesListLoading, dict: heroesDict } = useHeroesList();
  const {
    loading: heroStatsLoading,
    stats: heroStats,
    heroId,
    heroName,
  } = useHeroStats(heroIdOrName);

  return (
    <PageContainer>
      <HeroPageHeader
        heroId={heroId}
        heroName={heroName}
        stats={heroStats}
        titleSuffix={t("", "Lane synergies stats")}
      />
      <section className={Content()}>
        <div className="main-col">
          {heroStatsLoading ? (
            <DataTableLoading tallRows loadingRows={15} />
          ) : (
            <DataTable
              className={TableContainer()}
              tallRows
              indexCol
              sortCol={1} // win rate
              cols={[
                {
                  align: "left",
                  display: t("deadlock:common.laneTeammate", "Lane Teammate"),
                  className: "hero-meta",
                },
                {
                  display: t("common:stats.winRate", "Win Rate"),
                  className: "winrate",
                  isStat: true,
                },
                {
                  display: t("common:stats.wrImprovement", "WR Improvement"),
                  className: "winrate-improvement",
                  isStat: true,
                },
                {
                  display: t("common:stats.duoRate", "Duo Rate"),
                  className: "duo-rate",
                  isStat: true,
                },
                {
                  display: t("common:stats.matches", "Matches"),
                  className: "matches",
                  isStat: true,
                },
              ]}
              rows={(heroStats.top_lane_ally_heroes || [])
                .filter((duo) => duo.num_matches)
                .map((duo) => {
                  const hero = heroesDict[duo.lane_ally_hero];
                  const wrPrefix =
                    duo.combined_win_rate_improvement > 0 ? "+" : "";
                  return [
                    {
                      display: (
                        <div className="hero-meta">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "var(--sp-1)",
                            }}
                          >
                            <div className="hero-img" style={{ opacity: 0.5 }}>
                              <img
                                src={heroId && Assets.getHeroPortrait(heroId)}
                                width="28"
                                height="38"
                                alt={`${heroName} Deadlock Hero`}
                              />
                            </div>
                            {/* {"+"} */}
                            <div className="hero-img">
                              <img
                                src={
                                  hero.heroId &&
                                  Assets.getHeroPortrait(hero.heroId)
                                }
                                width="28"
                                height="38"
                                alt={`${hero.heroName} Deadlock Hero`}
                              />
                            </div>
                          </div>
                          <span className="shade0">{hero.heroName}</span>
                        </div>
                      ),
                      value: hero?.heroName,
                    },
                    {
                      display: localeString(duo.win_rate, true, 1),
                      value: duo.win_rate,
                    },
                    {
                      display: `${wrPrefix}${localeString(
                        duo.combined_win_rate_improvement,
                        true,
                        1,
                      )}`,
                      value: duo.combined_win_rate_improvement,
                    },
                    {
                      display: localeString(duo.pick_rate, true, 1),
                      value: duo.pick_rate,
                    },
                    {
                      display: localeString(duo.num_player_matches, false, 0),
                      value: duo.num_player_matches,
                    },
                  ];
                })}
            />
          )}
        </div>
        <aside className="side-col">
          <HeroesLinksList heroIdOrName={heroIdOrName} tab="synergies" />
        </aside>
      </section>
    </PageContainer>
  );
}

export default Herosynergies;

export function meta([heroIdOrName]: [string]) {
  const heroId = getHeroIdFromName(heroIdOrName, readState.deadlock.heroes);
  const hero = readState.deadlock.heroes[heroId];
  const name = hero?.heroName;
  return {
    title: name
      ? [
          "deadlock:meta.heroPage.title",
          "{{name}}'s Deadlock Win Rate, Pick Rate, and tier list – Blitz Deadlock",
          { name },
        ]
      : [
          "deadlock:meta.tierlist.title",
          "Deadlock Tier list, Win Rates, Pick Rates, and more – Blitz Deadlock",
        ],
    description: [],
    subtitle: name
      ? [
          "deadlock:meta.heroNameSubtitle",
          "Deadlock {{name}} Abilities Stats, Item builds, Matchups, Counters, Synergies, and more for Valve's new Shooter MOBA.",
          { name },
        ]
      : [
          "deadlock:meta.heroSubtitle",
          "Deadlock Hero Abilities Stats, Item builds, Matchups, Counters, Synergies, and more for Valve's new Shooter MOBA.",
        ],
  };
}
